import { RouteConfig } from "vue-router";
import APP_CONFIG from "@/apps/core/modules/config";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";

const baseName = "aktivitas";
const baseUrl = `/${baseName}`;

const kegiatanBaseName = "kegiatan-pendukung";
const kegiatanBaseUrl = `/${kegiatanBaseName}`;

const routes: Array<RouteConfig> = [
  {
    path: kegiatanBaseUrl,
    name: `${kegiatanBaseName}-list`,
    component: () =>
      import(
        /* webpackChunkName: "kegiatanpendukung" */ "../views/KegiatanPendukungList.vue"
      ),
    meta: {
      groups: ["admin", "konsulen", "residen"],
      permissions: ["aktivitas.view_kegiatanpendukung"],
    },
  },
  {
    path: `${kegiatanBaseUrl}/tambah`,
    name: `${kegiatanBaseName}-add`,
    component: () =>
      import(
        /* webpackChunkName: "kegiatanpendukung" */ "../views/KegiatanPendukungForm.vue"
      ),
    meta: {
      groups: ["residen"],
      permissions: ["aktivitas.add_kegiatanpendukung"],
    },
  },
  {
    path: `${kegiatanBaseUrl}/${APP_CONFIG.idURLPattern}/ubah`,
    name: `${kegiatanBaseName}-change`,
    component: () =>
      import(
        /* webpackChunkName: "kegiatanpendukung" */ "../views/KegiatanPendukungForm.vue"
      ),
    meta: {
      groups: ["admin", "konsulen", "residen"],
      permissions: ["aktivitas.view_kegiatanpendukung"],
    },
  },
  {
    path: `${kegiatanBaseUrl}/${APP_CONFIG.idURLPattern}`,
    name: `${kegiatanBaseName}-detail`,
    component: () =>
      import(
        /* webpackChunkName: "kegiatanpendukung" */ "../views/KegiatanPendukungDetail.vue"
      ),
    meta: {
      groups: ["admin", "konsulen", "residen"],
      permissions: ["aktivitas.view_kegiatanpendukung"],
    },
  },

  // AktivitasKlinis router
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "",
        name: `${baseName}-list`,
        component: () =>
          import(
            /* webpackChunkName: "aktivitasresiden" */ "../views/AktivitasKlinisList.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["aktivitas.view_aktivitasresiden"],
        },
      },
      {
        path: "tambah",
        name: `${baseName}-add`,
        component: () =>
          import(
            /* webpackChunkName: "aktivitasresiden" */ "../views/AktivitasKlinisForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["aktivitas.add_aktivitasresiden"],
        },
      },
      {
        path: `${APP_CONFIG.idURLPattern}/ubah`,
        name: `${baseName}-change`,
        component: () =>
          import(
            /* webpackChunkName: "aktivitasresiden" */ "../views/AktivitasKlinisForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["aktivitas.change_aktivitasresiden"],
        },
      },
      {
        path: APP_CONFIG.idURLPattern,
        name: `${baseName}-detail`,
        component: () =>
          import(
            /* webpackChunkName: "aktivitasresiden" */ "../views/AktivitasKlinisDetail.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["aktivitas.view_aktivitasresiden"],
        },
      },
      {
        path: `${APP_CONFIG.aktivitasIdURLPattern}/diagnosis/tambah`,
        name: `${baseName}-diagnosis-add`,
        props: { headerText: "DIAGNOSIS" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitasdiagnosis" */ "../views/AktivitasDiagnosisForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["aktivitas.add_aktivitasdiagnosis"],
        },
      },
      {
        path: `diagnosis${APP_CONFIG.idURLPattern}/ubah`,
        name: `${baseName}-diagnosis-change`,
        props: { headerText: "DIAGNOSIS" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitasdiagnosis" */ "../views/AktivitasDiagnosisForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["aktivitas.change_aktivitasdiagnosis"],
        },
      },
      {
        path: `diagnosis/${APP_CONFIG.idURLPattern}`,
        name: `${baseName}-diagnosis-detail`,
        props: { headerText: "DIAGNOSIS" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitasdiagnosis" */ "../views/AktivitasDiagnosisDetail.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["aktivitas.view_aktivitasdiagnosis"],
        },
      },
      {
        path: `${APP_CONFIG.aktivitasIdURLPattern}/pemeriksaan/tambah`,
        name: `${baseName}-pemeriksaan-add`,
        props: { headerText: "PEMERIKSAAN" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitaspemeriksaan" */ "../views/AktivitasPemeriksaanForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["aktivitas.add_aktivitaspemeriksaan"],
        },
      },
      {
        path: `pemeriksaan/${APP_CONFIG.idURLPattern}/ubah`,
        name: `${baseName}-pemeriksaan-change`,
        props: { headerText: "PEMERIKSAAN" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitaspemeriksaan" */ "../views/AktivitasPemeriksaanForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["aktivitas.change_aktivitaspemeriksaan"],
        },
      },
      {
        path: `pemeriksaan/${APP_CONFIG.idURLPattern}`,
        name: `${baseName}-pemeriksaan-detail`,
        props: { headerText: "PEMERIKSAAN" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitaspemeriksaan" */ "../views/AktivitasKomponenDetail.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["aktivitas.view_aktivitaspemeriksaan"],
        },
      },
      {
        path: `${APP_CONFIG.aktivitasIdURLPattern}/tatalaksana/tambah`,
        name: `${baseName}-tatalaksana-add`,
        props: { headerText: "TATALAKSANA" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitastatalaksana" */ "../views/AktivitasTatalaksanaForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["aktivitas.add_aktivitastatalaksana"],
        },
      },
      {
        path: `tatalaksana/${APP_CONFIG.idURLPattern}/ubah`,
        name: `${baseName}-tatalaksana-change`,
        props: { headerText: "TATALAKSANA" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitastatalaksana" */ "../views/AktivitasTatalaksanaForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["aktivitas.change_aktivitastatalaksana"],
        },
      },
      {
        path: `tatalaksana/${APP_CONFIG.idURLPattern}`,
        name: `${baseName}-tatalaksana-detail`,
        props: { headerText: "TATALAKSANA" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitastatalaksana" */ "../views/AktivitasKomponenDetail.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["aktivitas.view_aktivitastatalaksana"],
        },
      },
      {
        path: `${APP_CONFIG.aktivitasIdURLPattern}/keterampilan/tambah`,
        name: `${baseName}-keterampilan-add`,
        props: { headerText: "KETERAMPILAN" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitasketerampilan" */ "../views/AktivitasKeterampilanForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["aktivitas.add_aktivitasketerampilan"],
        },
      },
      {
        path: `keterampilan/${APP_CONFIG.idURLPattern}/ubah`,
        name: `${baseName}-keterampilan-change`,
        props: { headerText: "KETERAMPILAN" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitasketerampilan" */ "../views/AktivitasKeterampilanForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["aktivitas.change_aktivitasketerampilan"],
        },
      },
      {
        path: `keterampilan/${APP_CONFIG.idURLPattern}`,
        name: `${baseName}-keterampilan-detail`,
        props: { headerText: "KETERAMPILAN" },
        component: () =>
          import(
            /* webpackChunkName: "aktivitasketerampilan" */ "../views/AktivitasKeterampilanDetail.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["aktivitas.view_aktivitasketerampilan"],
        },
      },
    ],
  },
];

export default routes;
