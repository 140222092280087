import axios from "axios";
import createAuthRefreshInterceptor, {
  AxiosAuthRefreshRequestConfig,
} from "axios-auth-refresh";
import { Location } from "vue-router";
import { SnackbarProgrammatic as Snackbar } from "buefy";
import APP_CONFIG from "@/apps/core/modules/config";
import { isOnline, setOnline } from "@/apps/core/modules/store";
import router from "@/router";
import stateRef, { isAuthenticated } from "@/apps/accounts/modules/store";
import { TokenPair } from "@/apps/accounts/models/me";

const showSnackbar = (messageText: string, actionText: string) => {
  Snackbar.open({
    message: messageText,
    type: "is-warning",
    actionText: actionText,
    indefinite: true,
    onAction: () => {
      if (!isOnline.value) window.location.reload();
    },
  });
}

const setRequestInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      // config.url? diperlukan karena url bisa undefined
      const targetHost = config.url?.split("/")[2];
      if (isAuthenticated.value && targetHost === APP_CONFIG.apiHOST) {
        const accessToken = stateRef.me.accessToken;
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

const refreshToken = async (): Promise<any> => {
  const refreshToken = stateRef.me.refreshToken;
  const url = APP_CONFIG.baseAPIURL + "/api/token/refresh/";
  // reset vuex account state
  stateRef.me.setToken(new TokenPair());
  try {
    // TODO: Cek kembali apakah perlu refreshConfig???
    const refreshConfig: AxiosAuthRefreshRequestConfig = {
      skipAuthRefresh: true,
    };
    const response = await axios.post(
      url,
      { refresh: refreshToken },
      refreshConfig
    );
    const newTokenPair = new TokenPair(
      response.data.refresh,
      response.data.access
    );
    stateRef.me.setToken(newTokenPair);
    // return Promise.resolve();
  } catch (error) {
    const curRoute = router.currentRoute;
    if (curRoute.name !== "login") {
      const routerTo: Location = { name: "login" };
      if (curRoute.path !== "/") {
        routerTo.query = { next: curRoute.path };
      }
      router.push(routerTo);
    }
    // ganti promise.reject(error) dengan throw error
    throw new Error(error);
  }
}

const setResponseInterceptor = () => {
  axios.interceptors.response.use(
    (response) => {
      if (!isOnline.value) {
        setOnline(true);
      }
      return response;
    },
    (error) => {
      if (typeof error.response == "undefined") {
        // && isOnline) {
        showSnackbar("Tidak bisa terhubung dengan server.", "Retry");
        setOnline(false);
      }
      return Promise.reject(error);
    }
  );
}

const setAxiosInterceptors = () => {
  setRequestInterceptor();
  setResponseInterceptor();
  // Instantiate the interceptor (you can chain it as it returns the axios instance)
  createAuthRefreshInterceptor(axios, refreshToken);
}

export default setAxiosInterceptors;
