import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

export class Ujian extends PlainModel {
  judul: string | null = null;
  residen: Record<string, string> | null = null; //!!!!
  tanggalUjian: string | null = null;
  mengulang: boolean | null = null;
  rencanaUjian: string | null = null;
  jenis: Record<string, string> | null = null; //!!!!
  ketua: Record<string, string> | null = null; //!!!!
  sekretaris: Record<string, string> | null = null; //!!!!
  pengujiSet: Array<string> | null = null; //!!!!
  dokumenSet: Array<Record<string, string>> | null = null; //!!!!
  hasil: string | null = null;
  verifikasi: string | null = null;
  nilai: string | null = null;
  foto: string | null = null;

  loadData(json: Record<string, any>) {
    // ujian dibungkus dengan ujiansearch untuk mempermudah filtering
    const ujian = json["ujian"];
    if (ujian["nilai"] !== undefined && ujian["nilai"] !== null)
      ujian["nilai"] = ujian["nilai"].toString();

    const pengujiSet = ujian["penguji_set"] as Array<Record<string, any>>;
    ujian["penguji_set"] = pengujiSet.map((penguji) => penguji.konsulen);
    ujian.extraData = json["extra_data"];
    if (ujian.nilai !== null) ujian.nilai = parseFloat(ujian.nilai);
    super.loadData(ujian);
  }

  // getPayload() {
  //   const payload = super.getPayload();
  //   if (payload.mengulang === null) payload.mengulang = false;
  //   return payload;
  // }
}

export class UjianAPI extends API {
  static modelPath = "/ujian/";

  constructor() {
    super(UjianAPI.modelPath);
  }
}

export default class UjianVM extends ViewModel {
  constructor() {
    super(new UjianAPI(), new Ujian());
  }
}
