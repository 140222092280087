import { Angkatan, AngkatanAPI } from "./angkatan";
import ListAPI from "@/apps/core/api/list";
import ViewListModel from "@/apps/core/models/viewListModel";

export class AngkatanListAPI extends ListAPI {
  constructor() {
    super(AngkatanAPI.modelPath);
  }
}

export default class AngkatanListVM extends ViewListModel {
  constructor() {
    super(new AngkatanListAPI());
  }

  async fetch(params = {} as Record<string, any>) {
    if (this.filterMap.q) {
      // mengganti get parameter "q" menjadi "tahun".
      params.tahun = this.filterMap.q;
      this.filterMap.q = "";
    }
    await super.fetch(params);
  }

  // loadData(jsonArr: Array<Record<string, any>>) {
  //   const sorted = jsonArr.sort((el1, el2) => (el1.mulai > el2.mulai ? -1 : 1));
  //   super.loadData(sorted);
  // }

  getInstance(json: Record<string, any>) {
    const angkatan = new Angkatan();
    angkatan.loadData(json);
    return angkatan;
  }
}
