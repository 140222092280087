import { computed, reactive } from "@vue/composition-api";
import APP_CONFIG from "@/apps/core/modules/config";
import { Me } from "@/apps/accounts/models/me";

const state: Record<"me", Me> = { me: new Me() };

// State
const stateRef = reactive(state);
export default stateRef;

// Mutations
const setMe = (me: Me) => (state.me = me);

// Getters
const isAuthenticated = computed(() => !!state.me.refreshToken);
const hasUserId = computed(() => !!state.me.id);
const isAdmin = computed(() => state.me.group === APP_CONFIG.groupEnum.admin);
const isKonsulen = computed(
  () => state.me.group === APP_CONFIG.groupEnum.konsulen
);
const isResiden = computed(
  () => state.me.group === APP_CONFIG.groupEnum.residen
);
const residenId = computed(() => state.me.anggota?.residen?.id);

export { isAuthenticated, hasUserId, isAdmin, isKonsulen, isResiden, residenId, setMe };
