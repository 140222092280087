/*  Ujian store */

import UjianListVM from "../models/ujianList";

const listVM = new UjianListVM();

// Mutations
const setRefreshRequest = (
  requestBy: string,
  filterMap?: Record<string, string>
) => {
  listVM.setRefreshRequest(requestBy, filterMap);
};

export { listVM, setRefreshRequest };
