import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

export class Anggota extends PlainModel {
  nama: string | null = null;
  inisial: string | null = null;
  email: string | null = null;
  gender: string | null = null;
  appuserId: string | null = null;
  appuserUsername: string | null = null;
  foto: string | null = null;
  noHp: string | null = null;
  alamat: string | null = null;
  catatan: string | null = null;
  residen: Record<string, any> | null = null;
  konsulen: Record<string, string> | null = null;
  posisi: Record<string, string> | null = null;

  loadData(json: Record<string, any>) {
    json.email = json.appuser_username;
    super.loadData(json);
  }
}

export class AnggotaAPI extends API {
  static modelPath = "/anggota/";

  constructor() {
    super(AnggotaAPI.modelPath);
  }
}

export default class AnggotaVM extends ViewModel {
  constructor() {
    super(new AnggotaAPI(), new Anggota());
  }
}

export class DokterBase extends PlainModel {
  anggota: Anggota | null = null;
}
