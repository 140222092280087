import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

export class Perawatan extends PlainModel {
  pasien: Record<string, string> | null = null;
  verified: boolean | null = null;
  kodeBill: string | null = null;
  jenisPerawatan: string | null = null;
  tanggalMasuk: string | null = null;
  tanggalKeluar: string | null = null;
  pembuat: Record<string, string> | null = null;
  penutup: Record<string, string> | null = null;
  jenisLokasi: string | null = null;

  aktivitasList: Array<Record<string, string>> | null = null;
  diagnosisList: Array<Record<string, string>> | null = null;

  loadData(json: Record<string, any>) {
    // perawatan dibungkus dengan perawatansearch untuk mempermudah filtering
    const adjJson = json["perawatan"];
    adjJson.extraData = json["extra_data"];
    adjJson.pasien.nama = `${adjJson.pasien['no_rm']} - ${adjJson.pasien.nama}`;
    super.loadData(adjJson);
  }
}

export class PerawatanAPI extends API {
  static modelPath = "/perawatan/";

  constructor() {
    super(PerawatanAPI.modelPath);
  }
}

export default class PerawatanVM extends ViewModel {
  constructor() {
    super(new PerawatanAPI(), new Perawatan());
  }
}
