import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

class Angkatan extends PlainModel {
  mulai: string | null = null;
  tahun: number | null = null;
  residenSet: Array<Record<string, string>> | null = null;
}

class AngkatanAPI extends API {
  static modelPath = "/programstudi/angkatan/";

  constructor() {
    super(AngkatanAPI.modelPath);
  }
}

export default class AngkatanVM extends ViewModel {
  constructor() {
    super(new AngkatanAPI(), new Angkatan());
  }
}

export { Angkatan, AngkatanAPI };
