const DEV = false;

let API_DOMAIN_NAME, API_PORT, API_HOST, BASE_API_URL;

if (DEV) {
  API_DOMAIN_NAME = "192.168.2.12";
  API_PORT = "8000";
  API_HOST = API_DOMAIN_NAME + ":" + API_PORT;
  BASE_API_URL = "http://" + API_HOST;
} else {
  API_DOMAIN_NAME = "eliapi.fkunpad.id";
  API_HOST = API_DOMAIN_NAME;
  BASE_API_URL = "https://" + API_HOST;
}

const BASE_S3_URL = "https://fkunpad.s3.amazonaws.com";
const DIAGNOSIS_ICD10 = "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search";

const ID_REGEX_PATTERN =
  "([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})";

enum GROUP_ENUM {
  anonymous = "anonymous",
  admin = "admin",
  residen = "residen",
  konsulen = "konsulen",
}

const RESIZE_WIDTH = 800;
const RESIZE_HEIGHT = 800;
const RESIZE_QUALITY = 0.8;

const APP_CONFIG = Object.freeze({
  dev: DEV,
  apiHOST: API_HOST,
  baseS3Url: BASE_S3_URL,
  diagnosisIcd10: DIAGNOSIS_ICD10,
  brandText: "ELI",
  subBrandText: "Logbook IPD",
  baseAPIURL: BASE_API_URL,
  groupEnum: GROUP_ENUM,
  aktivitasIdURLPattern: `/:aktivitasId${ID_REGEX_PATTERN}`,
  //idURLPattern: '/:id',
  idURLPattern: `:id${ID_REGEX_PATTERN}`,
  keepAliveExcludePattern: "(.*?)(Home|Update|Detail|Create|Form)",
  kompIdURLPattern: `/:kompId${ID_REGEX_PATTERN}`,
  mobileScreenWidth: 768,
  perawatanIdURLPattern: `/:perawatanId${ID_REGEX_PATTERN}`,
  publicPages: [
    "/login",
    `/reset-password/${ID_REGEX_PATTERN}/`,
    "/request-reset-password",
  ],
  // pagination: { limit: 10 } as Record<string, number>,
  paginationLimit: 10,
  paginationFirstPageLimit: 20,
  resizeHeight: RESIZE_HEIGHT,
  resizeQuality: RESIZE_QUALITY,
  resizeWidth: RESIZE_WIDTH,
  subDeptIdURLPattern: `/:subDeptId${ID_REGEX_PATTERN}`,
  textLimit: 15,
});

export default APP_CONFIG;
