/* Anggota store */

import { AnggotaListVM } from "../models/anggotaList";

const listVM = new AnggotaListVM();

// Mutations
const setRefreshRequest = (
  requestBy: string,
  filterMap: Record<string, string> | undefined
) => {
  listVM.setRefreshRequest(requestBy, filterMap);
};

export { listVM, setRefreshRequest };
