import { Stase, StaseAPI } from "./stase";
import ListAPI from "@/apps/core/api/list";
import ViewListModel from "@/apps/core/models/viewListModel";

export class StaseListAPI extends ListAPI {
  constructor() {
    super(StaseAPI.modelPath);
  }
}

export default class StaseListVM extends ViewListModel {
  constructor() {
    super(new StaseListAPI());
  }

  getInstance(json: Record<string, any>) {
    const stase = new Stase();
    stase.loadData(json);
    return stase;
  }
}
