import APP_CONFIG from "@/apps/core/modules/config";
import axios, { AxiosResponse } from "axios";
import { ToastProgrammatic as Toast } from "buefy";
import { isArray } from "lodash";

export abstract class APIBase {
  /* Class ini digunakan oleh APIModel dan APIListModel */
  apiUrl = "";

  constructor(modelPath: string) {
    this.apiUrl = `${APP_CONFIG.baseAPIURL}${modelPath}`;
  }

  getAPIUrl(): string {
    return this.apiUrl;
  }

  setAPIUrl(url: string) {
    this.apiUrl = url;
  }
}

export abstract class API extends APIBase {
  createUrl?: string;
  updateUrl?: string;

  constructor(modelPath: string) {
    super(modelPath);
  }

  getErrorData(response: AxiosResponse<any>): Record<string, boolean | string> {
    const data = response.data;
    const errData = { error: true };
    if (isArray(data)) {
      for (const err of data) {
        Object.assign(errData, err);
      }
    } else {
      Object.assign(errData, response.data);
    }
    return errData;
  }

  async fetch(
    id: string,
    params = {} as Record<string, any>
  ): Promise<Record<string, any>> {
    const url = `${this.getAPIUrl()}${id}/`;
    const response = await axios.get(url, { params: params });
    // error akan di-catch di viewmodel / form!!!
    return response.data;
  }

  getCreateUrl(): string {
    return this.createUrl ? this.createUrl : this.getAPIUrl();
  }

  async create(
    payload: Record<string, any>,
    params = {} as Record<string, any>
  ): Promise<Record<string, any>> {
    const url = this.getCreateUrl();
    const commit = params.commit;
    try {
      const response = await axios.post(url, payload, { params: params });
      if (commit) Toast.open("Data berhasil disimpan.");
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        return this.getErrorData(error.response);
      }
      const errorMessage = "Gagal menyimpan data.";
      Toast.open(errorMessage);
      throw new Error(errorMessage);
    }
  }

  getUpdateUrl(id: string): string {
    return this.updateUrl ? this.updateUrl : `${this.getAPIUrl()}${id}/`;
  }

  async update(
    id: string,
    payload: Record<string, any>,
    params = {} as Record<string, any>
  ): Promise<Record<string, any>> {
    const url = this.getUpdateUrl(id);
    const commit = params.commit;
    try {
      const response = await axios.patch(url, payload, { params: params });
      if (commit) Toast.open("Data berhasil disimpan.");
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        return this.getErrorData(error.response);
      }
      const errorMessage = "Gagal menyimpan data.";
      Toast.open(errorMessage);
      throw new Error(errorMessage);
    }
  }

  async delete(id: string): Promise<Record<string, any>> {
    const url = `${this.getAPIUrl()}${id}/`;
    try {
      const response = await axios.delete(url);
      Toast.open("Data berhasil dihapus.");
      return response.data;
    } catch (error) {
      const errorMessage = "Gagal menghapus data.";
      Toast.open(errorMessage);
      throw new Error(errorMessage);
    }
  }
}
