






import { defineComponent } from "@vue/composition-api";
import APP_CONFIG from "../modules/config";

export default defineComponent({
  setup() {
    return {
      exclude: new RegExp(APP_CONFIG.keepAliveExcludePattern),
    };
  },
});
