/* Karya Ilmiah store */

import KaryaIlmiahListVM from "../models/karyaIlmiahList";

const listVM = new KaryaIlmiahListVM();

// Mutations
const setRefreshRequest = (
  requestBy: string,
  filterMap?: Record<string, string>
) => {
  listVM.setRefreshRequest(requestBy, filterMap);
};

export { listVM, setRefreshRequest };
