import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

export class KaryaIlmiah extends PlainModel {
  judul: string | null = null;
  pembuat: Record<string, string> | null = null;
  residen: Record<string, string> | null = null;
  tanggal: string | null = null;
  tanggalMaju: string | null = null;
  metode: Record<string, string> | null = null;
  kegiatan: Record<string, string> | null = null;
  pengujiSet: Array<string> | null = null;
  pembimbingSet: Array<string> | null = null;
  dokumenSet: Array<Record<string, string>> | null = null;
  hasil: string | null = null;
  verifikasi: string | null = null;
  nilai: string | null = null;

  loadData(json: Record<string, any>) {
    // karya_ilmiah dibungkus dengan kisearch untuk mempermudah filtering
    const ki = json["karya_ilmiah"];
    if (ki.nilai !== undefined && ki.nilai !== null) {
      ki.nilai = ki.nilai.toString();
    }

    const pembimbingSet = ki["pembimbing_set"] as Array<Record<string, any>>;
    ki["pembimbing_set"] = pembimbingSet.map(
      (pembimbing) => pembimbing.konsulen
    );

    const pengujiSet = ki["penguji_set"] as Array<Record<string, any>>;
    ki["penguji_set"] = pengujiSet.map((penguji) => penguji.konsulen);
    ki.extraData = json["extra_data"];
    if (ki.nilai !== null) ki.nilai = parseFloat(ki.nilai);
    super.loadData(ki);
  }

  // getPayload() {
  //   const payload = super.getPayload();
  //   payload.tanggal = toSystemDateFormat(payload.tanggal);
  //   console.log(payload)
  //   return payload;
  // }
}

export class KaryaIlmiahAPI extends API {
  static modelPath = "/karyailmiah/";

  constructor() {
    super(KaryaIlmiahAPI.modelPath);
  }
}

export default class KaryaIlmiahVM extends ViewModel {
  constructor() {
    super(new KaryaIlmiahAPI(), new KaryaIlmiah());
  }
}
