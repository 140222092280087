import Vue from "vue";
import VueRouter, { Location, Route, RouteConfig } from "vue-router";
import accountRoutes from "@/apps/accounts/modules/router";
import aktivitasRoutes from "@/apps/aktivitas/modules/router";
import anggotaRoutes from "@/apps/anggota/modules/router";
import APP_CONFIG from "./apps/core/modules/config";
import coreRoutes from "@/apps/core/modules/router";
import karyaIlmiahRoutes from "@/apps/karyailmiah/modules/router";
import MeVM, { Me } from "./apps/accounts/models/me";
import pasienRoutes from "@/apps/perawatan/modules/router";
import penyakitRoutes from "@/apps/penyakit/modules/router";
import programStudiRoutes from "@/apps/programstudi/modules/router";
import staseProgramStudiRoutes from "@/apps/stase/modules/router";
import stateRef, {
  hasUserId,
  isAuthenticated,
} from "./apps/accounts/modules/store";
import ujianRoutes from "@/apps/ujian/modules/router";

Vue.use(VueRouter);

const notFoundRoutes = [
  {
    path: "*",
    name: "not-found",
    meta: { status: "not-found" },
  },
];

let routes: Array<RouteConfig> = [];

routes = routes.concat(
  accountRoutes,
  aktivitasRoutes,
  anggotaRoutes,
  coreRoutes,
  karyaIlmiahRoutes,
  pasienRoutes,
  penyakitRoutes,
  programStudiRoutes,
  staseProgramStudiRoutes,
  ujianRoutes,
  notFoundRoutes // harus di paling terakhir!!!
);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(_to, _from, savedPosition) {
    // to.meta.fromHistory = savedPosition !== null;
    // to.meta.prevRoute = from;
    // savedPosition menentukan apakah goback atau tidak
    if (savedPosition) {
      // Load (scroll) savedposition sebelumnya
      return savedPosition;
    } else {
      // Scroll to top of page if the user didn't press the back button
      document.getElementById("app")?.scrollIntoView();
      //return { x: 0, y: 0 }
    }
  },
  routes,
});

const checkPermissions = (to: Route, next: Function) => {
  let hasGroup = true;
  if (to.meta && "groups" in to.meta) {
    hasGroup = to.meta.groups.includes(stateRef.me.group);
  }

  let hasPerms = true;
  if (to.meta && "permissions" in to.meta) {
    const userPerms = stateRef.me.permissions ?? [];
    const appPerms = to.meta.permissions;
    hasPerms = appPerms.every((perm: string) => userPerms.includes(perm));
  }

  if (to.meta && !(hasGroup && hasPerms)) {
    to.meta.status = "forbidden";
  }
  next();
};

router.beforeEach((to, _from, next) => {
  for (const path of APP_CONFIG.publicPages) {
    if (to.path.match(path) != null) {
      next();
      return;
    }
  }
  if (!isAuthenticated.value) {
    const nextOption: Location = { name: "login" };
    if (to.name !== "root") {
      nextOption.query = { next: to.path };
    }
    next(nextOption);
  } else {
    if (isAuthenticated.value && !hasUserId.value) {
      const meVM = new MeVM(stateRef.me as Me);
      meVM
        .fetch()
        .then(() => {
          checkPermissions(to, next);
        })
        .catch((error) => {
          if (typeof error.response == "undefined" && to.meta) {
            to.meta.status = "offline";
            next();
          }
        });
    } else {
      checkPermissions(to, next);
    }
  }
});

export default router;
