import { RouteConfig } from "vue-router";
import APP_CONFIG from "@/apps/core/modules/config";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";

const baseName = "perawatan";
const baseUrl = `/${baseName}`;
const basePasienName = "pasien";
const basePasienUrl = `/${basePasienName}`;

const routes: Array<RouteConfig> = [
  // Perawatan router
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "",
        name: `${baseName}-list`,
        component: () =>
          import(
            /* webpackChunkName: "perawatan" */ "../views/PerawatanList.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["perawatan.view_perawatan"],
        },
      },
      {
        path: APP_CONFIG.idURLPattern,
        name: `${baseName}-detail`,
        component: () =>
          import(
            /* webpackChunkName: "perawatan" */ "../views/PerawatanDetail.vue"
          ), // PERBAIKI!!!!
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["perawatan.view_perawatan"],
        },
      },
      {
        path: "tambah",
        name: `${baseName}-add`,
        props: { headerText: "TAMBAH" },
        component: () =>
          import(
            /* webpackChunkName: "perawatan" */ "../views/PerawatanForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["perawatan.add_perawatan"],
        }, //  mungkin kedepannya admin perlu bisa buat juga
      },
      {
        path: `${APP_CONFIG.idURLPattern}/ubah`,
        name: `${baseName}-change`,
        props: { headerText: "UBAH" },
        component: () =>
          import(
            /* webpackChunkName: "perawatan" */ "../views/PerawatanForm.vue"
          ),
        meta: {
          groups: ["residen"],
          permissions: ["perawatan.change_perawatan"],
        }, //  mungkin kedepannya admin perlu bisa buat juga
      },
    ],
  },
  // Pasien router
  {
    path: basePasienUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "",
        name: `${basePasienName}-list`,
        component: () =>
          import(/* webpackChunkName: "perawatan" */ "../views/PasienList.vue"),
      },
      {
        path: APP_CONFIG.idURLPattern,
        name: `${basePasienName}-detail`,
        component: () =>
          import(
            /* webpackChunkName: "perawatan" */ "../views/PasienDetail.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["perawatan.view_pasien"],
        },
      },
      {
        path: "tambah",
        name: `${basePasienName}-add`,
        props: { headerText: "TAMBAH" },
        component: () =>
          import(/* webpackChunkName: "perawatan" */ "../views/PasienForm.vue"),
        meta: {
          groups: ["residen", "admin"],
          permissions: ["perawatan.add_pasien"],
        }, //  mungkin kedepannya admin perlu bisa buat juga
      },
    ],
  },
];

export default routes;
