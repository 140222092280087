import ListAPI from "@/apps/core/api/list";
import {
  TargetKeterampilan,
  TargetKeterampilanAPI,
} from "./targetKeterampilan";
import ViewListModel from "@/apps/core/models/viewListModel";

export class TargetKeterampilanListAPI extends ListAPI {
  constructor() {
    super(TargetKeterampilanAPI.modelPath);
  }
}

export default class TargetKeterampilanListVM extends ViewListModel {
  constructor(batch = 'all') {
    super(new TargetKeterampilanListAPI());
    this.filterMap.batch = batch;
  }

  getInstance(json: Record<string, any>) {
    const target = new TargetKeterampilan();
    target.loadData(json);
    if (this.filterMap.batch === 'create') target.id = null;
    return target;
  }
}
