import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
import axios from "axios";

export class AktivitasKlinis extends PlainModel {
  perawatan: Record<string, string> | null = null;
  residen: Record<string, string> | null = null;
  lokasi: Record<string, string> | null = null;
  stase: Record<string, any> | null = null;
  tanggal: string | null = null;
  dpjp: Record<string, string> | null = null;
  catatan: string | null = null;
  verifikasi: boolean | null = null;

  aktKeterampilanList: Array<Record<string, string>> | null = null;
  aktPemeriksaanList: Array<Record<string, string>> | null = null;
  aktTatalaksanaList: Array<Record<string, string>> | null = null;
  fotoList: Array<Record<string, string>> | null = null;

  loadData(json: Record<string, any>) {
    // aktivitasklinis dibungkus dengan aktivitassearch untuk mempermudah filtering
    const adjJson = json["aktivitas"];
    adjJson.extraData = json["extra_data"];
    super.loadData(adjJson);
  }

  async updateVerifikasi(
    url: string,
    aktDiagSet: Array<Record<string, string>>,
    aktKetSet: Array<Record<string, string>>
  ) {
    await axios.patch(url, {
      verifikasi: this.verifikasi,
      'akt_diag_set': aktDiagSet,
      'akt_ket_set': aktKetSet,
    });
  }
}

export class AktivitasKlinisAPI extends API {
  static modelPath = "/aktivitas/";

  constructor() {
    super(AktivitasKlinisAPI.modelPath);
  }
}

export default class AktivitasKlinisVM extends ViewModel {
  constructor() {
    super(new AktivitasKlinisAPI(), new AktivitasKlinis());
  }
}
