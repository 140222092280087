import { Informasi, InformasiAPI } from "./informasi";
import ListAPI from "@/apps/core/api/list";
import ViewListModel from "@/apps/core/models/viewListModel";

export class InformasiListAPI extends ListAPI {
  constructor() {
    super(InformasiAPI.modelPath);
  }
}

export default class InformasiListVM extends ViewListModel {
  constructor() {
    super(new InformasiListAPI());
  }

  async fetch(params = {} as Record<string, any>) {
    if (this.filterMap.q) params.tahun = this.filterMap.q;
    await super.fetch(params);
  }

  getInstance(json: Record<string, any>) {
    const informasi = new Informasi();
    informasi.loadData(json);
    informasi.created = informasi.created
      ? informasi.created.substr(0, 10)
      : null;
    return informasi;
  }
}
