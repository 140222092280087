import ListAPI from "@/apps/core/api/list";
import { Pasien, PasienAPI } from "./pasien";
import ViewListModel from "@/apps/core/models/viewListModel";

export class PasienListAPI extends ListAPI {
  constructor() {
    super(PasienAPI.modelPath);
  }
}

export default class PasienListVM extends ViewListModel {
  constructor() {
    super(new PasienListAPI());
  }

  getInstance(json: Record<string, any>) {
    const pasien = new Pasien();
    pasien.loadData(json);
    return pasien;
  }
}
