import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

class TargetDiagnosis extends PlainModel {
  angkatan: Record<string, string> | null = null;
  diagnosis: Record<string, string> | null = null;
  target: number | null = null;
}

class TargetDiagnosisAPI extends API {
  static modelPath = "/programstudi/target-diagnosis/";

  constructor() {
    super(TargetDiagnosisAPI.modelPath);
  }
}

export default class AngkatanVM extends ViewModel {
  constructor() {
    super(new TargetDiagnosisAPI(), new TargetDiagnosis());
  }
}

export { TargetDiagnosis, TargetDiagnosisAPI };
