import { SuratTugas, SuratTugasAPI } from "./suratTugas";
import ListAPI from "@/apps/core/api/list";
import ViewListModel from "@/apps/core/models/viewListModel";

export class SuratTugasListAPI extends ListAPI {
  constructor() {
    super(SuratTugasAPI.modelPath);
  }
}

export default class SuratTugasListVM extends ViewListModel {
  constructor() {
    super(new SuratTugasListAPI());
  }

  async fetch(params = {} as Record<string, any>) {
    if (this.filterMap.q) params.tahun = this.filterMap.q;
    await super.fetch(params);
  }

  getInstance(json: Record<string, any>) {
    const surat = new SuratTugas();
    surat.loadData(json);
    surat.created = surat.created
      ? surat.created.substr(0, 10)
      : null;
    return surat;
  }
}
