/*  TargetKeterampilan store */

import TargetKeterampilanListVM from "../../models/targetKeterampilanList";

const listVM = new TargetKeterampilanListVM();

// Mutations
const setRefreshRequest = (
  requestBy: string,
  filterMap: Record<string, string> | undefined
) => {
  listVM.setRefreshRequest(requestBy, filterMap);
};

export { listVM, setRefreshRequest };
