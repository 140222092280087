import { Anggota, AnggotaAPI } from "./anggota";
import ListAPI from "@/apps/core/api/list";
import ViewListModel from "@/apps/core/models/viewListModel";

export class AnggotaListAPI extends ListAPI {
  constructor() {
    super(AnggotaAPI.modelPath);
  }
}

export class AnggotaListVM extends ViewListModel {
  constructor() {
    super(new AnggotaListAPI());
  }

  getInstance(json: Record<string, any>) {
    const anggota = new Anggota();
    anggota.loadData(json);
    return anggota;
  }
}
