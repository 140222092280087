import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "root",
    redirect: { name: "home" },
    // component: () => import("@/apps/core/views/Home.vue")
  },
  {
    path: "/bantuan",
    name: "bantuan",
    component: () =>
      import(/* webpackChunkName: "bantuan" */ "../views/Bantuan.vue"),
    meta: {
      groups: ["admin", "konsulen", "residen"],
      permissions: ["anggota.view_anggota"],
    },
  },
];

export default routes;
