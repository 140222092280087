import APP_CONFIG from "@/apps/core/modules/config";
import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "accounts" */ "../views/Login.vue"),
    meta: { status: "login" },
  },
  {
    path: "/request-reset-password",
    name: "request-reset-password",
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "../views/RequestResetPassword.vue"
      ),
    meta: { status: "request-reset-password" },
  },
  {
    path: "/reset-password/" + APP_CONFIG.idURLPattern,
    name: "reset-password",
    component: () =>
      import(/* webpackChunkName: "accounts" */ "../views/ResetPassword.vue"),
    meta: { status: "reset-password" },
  },
  {
    path: "/ubah-sandi",
    name: "change-password",
    component: () =>
      import(/* webpackChunkName: "accounts" */ "../views/ChangePassword.vue"),
    meta: {
      status: "change-password",
      groups: ["admin", "konsulen", "residen"],
      permissions: ["core.change_appuser"],
    },
  },
];

export default routes;
