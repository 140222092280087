/*  Angkatan store */

import AngkatanListVM from "../../models/angkatanList";

const listVM = new AngkatanListVM();

// Mutations
const setRefreshRequest = (requestBy: string) =>
  listVM.setRefreshRequest(requestBy);

export { listVM, setRefreshRequest };
