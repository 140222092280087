import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

class TargetKeterampilan extends PlainModel {
  angkatan: Record<string, string> | null = null;
  keterampilan: Record<string, string> | null = null;
  target1: number | null = null;
  target2: number | null = null;
  target3: number | null = null;
}

class TargetKeterampilanAPI extends API {
  static modelPath = "/programstudi/target-keterampilan/";

  constructor() {
    super(TargetKeterampilanAPI.modelPath);
  }
}

export default class AngkatanVM extends ViewModel {
  constructor() {
    super(new TargetKeterampilanAPI(), new TargetKeterampilan());
  }
}

export { TargetKeterampilan, TargetKeterampilanAPI };
