import { RouteConfig } from "vue-router";
import APP_CONFIG from "@/apps/core/modules/config";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";

const baseName = "ujian";
const baseUrl = `/${baseName}`;

const routes: Array<RouteConfig> = [
  // Ujian router
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "",
        name: `${baseName}-list`,
        component: () =>
          import(/* webpackChunkName: "ujian" */ "../views/UjianList.vue"),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["ujian.view_ujian"],
        },
      },
      {
        path: APP_CONFIG.idURLPattern,
        name: `${baseName}-detail`,
        props: { title: "Ujian" },
        component: () =>
          import(/* webpackChunkName: "ujian" */ "../views/UjianDetail.vue"),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["ujian.view_ujian"],
        },
      },
      {
        path: "tambah",
        name: `${baseName}-add`,
        component: () =>
          import(/* webpackChunkName: "ujian" */ "../views/UjianForm.vue"),
        props: { headerText: "TAMBAH" },
        meta: {
          groups: ["admin"],
          permissions: ["ujian.add_ujian"],
        },
      },
      {
        path: `${APP_CONFIG.idURLPattern}/ubah`,
        name: `${baseName}-change`,
        component: () =>
          import(/* webpackChunkName: "ujian" */ "../views/UjianForm.vue"),
        props: { headerText: "UBAH" },
        meta: {
          groups: ["admin"],
          permissions: ["ujian.change_ujian"],
        },
      },
    ],
  },
];

export default routes;
