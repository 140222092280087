import ListAPI from "@/apps/core/api/list";
import { AktivitasKlinis, AktivitasKlinisAPI } from "./aktivitasKlinis";
import ViewListModel from "@/apps/core/models/viewListModel";

export class AktivitasKlinisListAPI extends ListAPI {
  constructor() {
    super(AktivitasKlinisAPI.modelPath);
  }
}

export class AktivitasKlinisListVM extends ViewListModel {
  constructor() {
    super(new AktivitasKlinisListAPI());
  }

  loadData(jsonArr: Array<Record<string, any>>) {
    for (const json of jsonArr) {
      // Cek apakah data sudah ada di-list, agar tidak muncul vue duplicate key error
      if (!this.idSet.has(json.aktivitas_id)) {
        this.list.push(this.getInstance(json));
        this.idSet.add(json.aktivitas_id);
      }
    }
  }

  getInstance(json: Record<string, any>) {
    const aktivitasKlinis = new AktivitasKlinis();
    aktivitasKlinis.loadData(json);
    return aktivitasKlinis;
  }
}
