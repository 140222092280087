import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

class SuratTugas extends PlainModel {
  created: string | null = null;
  konsulen: Record<string, string> | null = null;
  url: string | null = null;
  catatan: string | null = null;
}

class SuratTugasAPI extends API {
  static modelPath = "/programstudi/surat-tugas/";

  constructor() {
    super(SuratTugasAPI.modelPath);
  }
}

export default class SuratTugasVM extends ViewModel {
  constructor() {
    super(new SuratTugasAPI(), new SuratTugas());
  }
}

export { SuratTugas, SuratTugasAPI };
