/*  Perawatan store */

import { PerawatanListVM } from "../../models/perawatanList";

const listVM = new PerawatanListVM();

// Mutations
const setRefreshRequest = (
  requestBy: string,
  filterMap?: Record<string, string>
) => {
  listVM.setRefreshRequest(requestBy, filterMap);
};

export { listVM, setRefreshRequest };
