/*  Stase store */

import StaseListVM from "../models/staseList";

const listVM = new StaseListVM();

// Mutations
const setRefreshRequest = (
  requestBy: string,
  filterMap?: Record<string, string>
) => {
  listVM.setRefreshRequest(requestBy, filterMap);
};

export { listVM, setRefreshRequest };
