import { RouteConfig } from "vue-router";
import APP_CONFIG from "@/apps/core/modules/config";

const baseName = "stase";
const baseUrl = `/${baseName}`;

const routes: Array<RouteConfig> = [
  // Stase router
  {
    path: `${baseUrl}/tambah`,
    name: `${baseName}-add`,
    component: () =>
      import(
        /* webpackChunkName: "stase" */ "../views/StaseForm.vue"
      ),
    meta: {
      groups: ["admin"],
      permissions: ["stase.add_stase"],
    },
  },
  {
    path: baseUrl,
    name: `${baseName}-list`,
    component: () =>
      import(
        /* webpackChunkName: "stase" */ "../views/StaseList.vue"
      ),
    meta: {
      groups: ["admin", "konsulen", "residen"],
      permissions: ["stase.view_stase"],
    },
  },
  {
    path: `${baseUrl}${APP_CONFIG.idURLPattern}`,
    name: `${baseName}-detail`,
    component: () =>
      import(
        /* webpackChunkName: "stase" */ "../views/StaseDetail.vue"
      ),
    meta: {
      groups: ["admin", "konsulen", "residen"],
      permissions: ["stase.view_stase"],
    },
  },
  {
    path: `${baseUrl}${APP_CONFIG.idURLPattern}/ubah`,
    name: `${baseName}-change`,
    component: () =>
      import(
        /* webpackChunkName: "stase" */ "../views/StaseForm.vue"
      ),
    meta: {
      groups: ["admin"],
      permissions: ["stase.change_stase"],
    },
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/residen",
    name: baseName + "-residen-add",
    component: () => import("../views/StaseResidenBatchCreate.vue"),
    meta: {
      groups: ["admin"],
      permissions: ["stase.add_staseresiden"]
    }
  },
];

export default routes;
