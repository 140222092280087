import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

class Pasien extends PlainModel {
  noRm: string | null = null;
  nama: string | null = null;
  gender: string | null = null;
  tglLahir: string | null = null;
  catatan: string | null = null;
}

class PasienAPI extends API {
  static modelPath = "/perawatan/pasien/";

  constructor() {
    super(PasienAPI.modelPath);
  }
}

export default class PasienVM extends ViewModel {
  constructor() {
    super(new PasienAPI(), new Pasien());
  }
}

export { Pasien, PasienAPI };
