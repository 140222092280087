/* Aktivitas state */

import { computed, reactive } from "@vue/composition-api";
import { AktivitasKlinisListVM } from "../../models/aktivitasKlinisList";

const stateRec: Record<"aktivitas", any | null> = {
  aktivitas: null,
};

// State
const state = reactive(stateRec);

// Getters
const isOnline = computed(() => state.aktivitas);

// Mutations
const setAktivitas = (aktivitas: any) => (state.aktivitas = aktivitas);

const listVM = new AktivitasKlinisListVM();

// Mutations
const setRefreshRequest = (
  requestBy: string,
  filterMap?: Record<string, string>
) => {
  listVM.setRefreshRequest(requestBy, filterMap);
};

export { isOnline, listVM, setAktivitas, setRefreshRequest };
