import { API } from "@/apps/core/api";
import APP_CONFIG from "@/apps/core/modules/config";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
import { listVM as aktivitasListVM } from "@/apps/aktivitas/modules/stores/aktivitas";
import { listVM as anggotaListVM } from "@/apps/anggota/modules/store";
import { listVM as ilmiahListVM } from "@/apps/karyailmiah/modules/store";
import { listVM as kegiatanListVM } from "@/apps/aktivitas/modules/stores/kegiatan";
import { listVM as pasienListVM } from "@/apps/perawatan/modules/stores/pasien";
import { listVM as perawatanListVM } from "@/apps/perawatan/modules/stores/perawatan";
import { listVM as angkatanListVM } from "@/apps/programstudi/modules/stores/angkatan";
import { listVM as informasiListVM } from "@/apps/programstudi/modules/stores/informasi";
import { listVM as suratTugasListVM } from "@/apps/programstudi/modules/stores/suratTugas";
import { listVM as targetDiagnosisListVM } from "@/apps/programstudi/modules/stores/targetDiagnosis";
import { listVM as targetKeterampilanListVM } from "@/apps/programstudi/modules/stores/targetKeterampilan";
import { listVM as staseListVM } from "@/apps/stase/modules/store";
import { listVM as ujianListVM } from "@/apps/ujian/modules/store";

export class TokenPair {
  refreshToken: string;
  accessToken: string;

  constructor(refreshToken = "", accessToken = "") {
    this.refreshToken = refreshToken;
    this.accessToken = accessToken;
  }
}

export class Me extends PlainModel {
  // semua harus bisa null, karena ketika direset akan menjadi null
  refreshToken: string | null = null;
  accessToken: string | null = null;
  anggota: Record<string, any> | null = null;
  group: string | null = null;
  permissions: Array<string> | null = null;

  constructor() {
    super();
    this.setFieldInitialValues();
  }

  setToken(tokenPair: TokenPair) {
    this.refreshToken = tokenPair.refreshToken;
    this.accessToken = tokenPair.accessToken;
    localStorage.setItem("refreshToken", tokenPair.refreshToken);
    localStorage.setItem("accessToken", tokenPair.accessToken);
  }

  getResetData() {
    const blank = super.getResetData();
    // exclude refreshToken and accessToken!
    delete blank.refreshToken;
    delete blank.accessToken;
    return blank;
  }

  resetStores() {
    const stores = [
      aktivitasListVM, anggotaListVM, ilmiahListVM, kegiatanListVM,
      pasienListVM, perawatanListVM, angkatanListVM, informasiListVM,
      suratTugasListVM, targetDiagnosisListVM, targetKeterampilanListVM,
      staseListVM, ujianListVM
    ]
    for (const store of stores) {
      store.reset();
    }
  }

  logout() {
    this.resetStores();
    this.reset();
    this.setFieldInitialValues();
    this.setToken(new TokenPair());
    // TODO: Perlu hapus semua data yang di-cache
    // Reactive state, semua component yang di-keep-alive, dll
  }

  setFieldInitialValues() {
    this.refreshToken = localStorage.getItem("refreshToken") || "";
    this.accessToken = localStorage.getItem("accessToken") || "";
    this.group = APP_CONFIG.groupEnum.anonymous;
  }
}

export class MeAPI extends API {
  static modelPath = "/anggota/me/";

  constructor() {
    super(MeAPI.modelPath);
  }

  getAPIUrl() {
    // hilangkan slash terakhir dari url
    return super.getAPIUrl().slice(0, -1);
  }
}

export default class MeVM extends ViewModel {
  constructor(me: Me) {
    super(new MeAPI(), me);
  }

  async fetch() {
    // Override karena:
    // - pola url yang berbeda (tidak pakai pk)
    // - error akan dihandle di router.beforeEach
    const record = await this.api.fetch("");
    this.instance.loadData(record);
  }
}
