import ListAPI from "@/apps/core/api/list";
import { KaryaIlmiah, KaryaIlmiahAPI } from "./karyaIlmiah";
import ViewListModel from "@/apps/core/models/viewListModel";

export class KaryaIlmiahListAPI extends ListAPI {
  constructor() {
    super(KaryaIlmiahAPI.modelPath);
  }
}

export default class KaryaIlmiahListVM extends ViewListModel {
  constructor() {
    super(new KaryaIlmiahListAPI());
  }

  loadData(jsonArr: Array<Record<string, any>>) {
    for (const json of jsonArr) {
      // Cek apakah data sudah ada di-list, agar tidak muncul vue duplicate key error
      if (!this.idSet.has(json.karya_ilmiah_id)) {
        this.list.push(this.getInstance(json));
        this.idSet.add(json.karya_ilmiah_id);
      }
    }
  }

  getInstance(json: Record<string, any>) {
    const karyaIlmiah = new KaryaIlmiah();
    karyaIlmiah.loadData(json);
    return karyaIlmiah;
  }
}
