import ListAPI from "@/apps/core/api/list";
import { Perawatan, PerawatanAPI } from "./perawatan";
import ViewListModel from "@/apps/core/models/viewListModel";

export class PerawatanListAPI extends ListAPI {
  constructor() {
    super(PerawatanAPI.modelPath);
  }
}

export class PerawatanListVM extends ViewListModel {
  constructor() {
    super(new PerawatanListAPI());
  }

  loadData(jsonArr: Array<Record<string, any>>) {
    for (const json of jsonArr) {
      // Cek apakah data sudah ada di-list, agar tidak muncul vue duplicate key error
      if (!this.idSet.has(json.perawatan_id)) {
        this.list.push(this.getInstance(json));
        this.idSet.add(json.perawatan_id);
      }
    }
  }

  getInstance(json: Record<string, any>) {
    const perawatan = new Perawatan();
    perawatan.loadData(json);
    return perawatan;
  }
}
