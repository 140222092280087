import ListAPI from "@/apps/core/api/list";
import { KegiatanPendukung, KegiatanPendukungAPI } from "./kegiatanPendukung";
import ViewListModel from "@/apps/core/models/viewListModel";

export class KegiatanPendukungListAPI extends ListAPI {
  constructor() {
    super(KegiatanPendukungAPI.modelPath);
  }
}

export class KegiatanPendukungListVM extends ViewListModel {
  constructor() {
    super(new KegiatanPendukungListAPI());
  }

  getInstance(json: Record<string, any>) {
    const kegiatan = new KegiatanPendukung();
    kegiatan.loadData(json);
    return kegiatan;
  }
}
