import { RouteConfig } from "vue-router";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";

const baseName = "penyakit";
const baseUrl = `/${baseName}`;

const routes: Array<RouteConfig> = [
  // Penyakit router
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "tindakan/tambah",
        name: "tindakan-add",
        props: { headerText: "TINDAKAN" },
        component: () =>
          import(
            /* webpackChunkName: "penyakit" */ "../views/KomponenForm.vue"
          ),
        meta: {
          groups: ["residen"], // TODO: Pastikan hanya ada "residen"
          permissions: ["penyakit.add_tindakanketerampilan"],
        },
      },
      {
        path: "pemeriksaan/tambah",
        name: "pemeriksaan-add",
        props: { headerText: "PEMERIKSAAN" },
        component: () =>
          import(
            /* webpackChunkName: "penyakit" */ "../views/KomponenForm.vue"
          ),
        meta: {
          groups: ["residen"], // TODO: Pastikan hanya ada "residen"
          permissions: ["penyakit.add_pemeriksaanpenunjang"],
        },
      },
      {
        path: "tatalaksana/tambah",
        name: "tatalaksana-add",
        props: { headerText: "TATALAKSANA" },
        component: () =>
          import(
            /* webpackChunkName: "penyakit" */ "../views/KomponenForm.vue"
          ),
        meta: {
          groups: ["residen"], // TODO: Pastikan hanya ada "residen"
          permissions: ["penyakit.add_tatalaksana"],
        },
      },
      {
        path: "diagnosis/tambah",
        name: "diagnosis-add",
        props: { headerText: "DIAGNOSIS" },
        component: () =>
          import(
            /* webpackChunkName: "penyakit" */ "../views/DiagnosisForm.vue"
          ),
        meta: {
          groups: ["residen"], // TODO: Pastikan hanya ada "residen"
          permissions: ["penyakit.add_diagnosis"],
        },
      },
    ],
  },
];

export default routes;
