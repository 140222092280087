import { RouteConfig } from "vue-router";
import APP_CONFIG from "@/apps/core/modules/config";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";

const baseName = "programstudi";
const baseUrl = `/${baseName}`;

const angkatanName = "angkatan";
const targetDiagName = "target-diagnosis";
const targetKetName = "target-keterampilan";

const pengumumanName = "pengumuman";
// const informasiUrl = `/${pengumumanName}`;

const suratTugasName = "surat-tugas";
// const suratTugasUrl = `/${suratTugasName}`;

const routes: Array<RouteConfig> = [
  // Program Studi router
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      // Angkatan router
      {
        path: angkatanName,
        component: EmptyRouterView,
        children: [
          {
            path: "",
            name: `${angkatanName}-list`,
            component: () =>
              import(
                /* webpackChunkName: "programstudi" */ "../views/AngkatanList.vue"
              ),
            meta: {
              groups: ["admin"],
              permissions: ["programstudi.view_angkatan"],
            },
          },
          {
            path: APP_CONFIG.idURLPattern,
            name: `${angkatanName}-detail`,
            component: () =>
              import(
                /* webpackChunkName: "programstudi" */ "../views/AngkatanDetail.vue"
              ),
            meta: {
              groups: ["admin"],
              permissions: ["programstudi.view_angkatan"],
            },
          },
          {
            path: "tambah",
            name: `${angkatanName}-add`,
            component: () =>
              import(
                /* webpackChunkName: "programstudi" */ "../views/AngkatanForm.vue"
              ),
            meta: {
              groups: ["admin"],
              permissions: ["programstudi.add_angkatan"],
            },
          },
        ],
      },

      // Target
      {
        path: "target",
        component: EmptyRouterView,
        children: [
          {
            path: "diagnosis",
            component: EmptyRouterView,
            children: [
              {
                path: "",
                name: `${targetDiagName}-list`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/TargetDiagnosisList.vue"
                  ),
                meta: {
                  groups: ["admin"],
                  permissions: ["programstudi.view_targetdiagnosis"],
                },
              },
              {
                path: "tambah",
                name: `${targetDiagName}-add`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/TargetDiagnosisForm.vue"
                  ),
                meta: {
                  groups: ["admin"],
                  permissions: ["programstudi.add_targetdiagnosis"],
                },
              },
              {
                path: `${APP_CONFIG.idURLPattern}/ubah`,
                name: `${targetDiagName}-change`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/TargetDiagnosisForm.vue"
                  ),
                meta: {
                  groups: ["admin"],
                  permissions: ["programstudi.change_targetdiagnosis"],
                },
              },
              {
                path: "batch",
                name: `${targetDiagName}-batch`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/TargetDiagBatchList.vue"
                  ),
              },
            ],
          },

          {
            path: "keterampilan",
            component: EmptyRouterView,
            children: [
              {
                path: "",
                name: `${targetKetName}-list`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/TargetKeterampilanList.vue"
                  ),
                meta: {
                  groups: ["admin"],
                  permissions: ["programstudi.view_targetketerampilan"],
                },
              },
              {
                path: "tambah",
                name: `${targetKetName}-add`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/TargetKeterampilanForm.vue"
                  ),
                meta: {
                  groups: ["admin"],
                  permissions: ["programstudi.add_targetketerampilan"],
                },
              },
              {
                path: `${APP_CONFIG.idURLPattern}/ubah`,
                name: `${targetKetName}-change`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/TargetKeterampilanForm.vue"
                  ),
                meta: {
                  groups: ["admin"],
                  permissions: ["programstudi.change_targetketerampilan"],
                },
              },
              {
                path: "batch",
                name: `${targetKetName}-batch`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/TargetKetBatchList.vue"
                  ),
              },
            ],
          },
        ],
      },

      // Informasi
      {
        path: "informasi",
        component: EmptyRouterView,
        children: [
          {
            path: pengumumanName,
            component: EmptyRouterView,
            children: [
              {
                path: "",
                name: `${pengumumanName}-list`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/InformasiList.vue"
                  ),
                meta: {
                  groups: ["admin", "konsulen", "residen"],
                  permissions: ["programstudi.view_informasi"],
                },
              },
              {
                path: "tambah",
                name: `${pengumumanName}-add`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/InformasiForm.vue"
                  ),
                meta: {
                  groups: ["admin"],
                  permissions: ["programstudi.add_informasi"],
                },
              },
            ],
          },
          {
            path: suratTugasName,
            component: EmptyRouterView,
            children: [
              {
                path: "",
                name: `${suratTugasName}-list`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/SuratTugasList.vue"
                  ),
                meta: {
                  groups: ["admin", "konsulen"],
                  permissions: ["programstudi.view_surattugas"],
                },
              },
              {
                path: "tambah",
                name: `${suratTugasName}-add`,
                component: () =>
                  import(
                    /* webpackChunkName: "programstudi" */ "../views/SuratTugasForm.vue"
                  ),
                meta: {
                  groups: ["konsulen"],
                  permissions: ["programstudi.add_surattugas"],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
