/*  Pasien store */

import PasienListVM from "../../models/pasienList";

const listVM = new PasienListVM();

// Mutations
const setRefreshRequest = (requestBy: string) =>
  listVM.setRefreshRequest(requestBy);

export { listVM, setRefreshRequest };
