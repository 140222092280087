import { camelCase } from "lodash";

export default abstract class PlainModel {
  id: string | null = null;
  extraData: Record<string, string> | null = null; // TODO: Initialize dengan null dan test

  // Jangan dihapus kode ini!!!!
  // Buat catatan TIDAK bisa dengan cara ini, karena di-constructor
  // object-nya masih berupa plainmodel hanya ada field id.
  // constructor(json?: Record<string, any>) {
  //   console.log(this);
  //   if (json) this.loadData(json);
  // }

  private getAdjustedValue(json: any): any {
    if (json === null || typeof json === "undefined") {
      return json;
    }

    if (typeof json === "object") {
      if (Array.isArray(json)) {
        const arr = [];
        for (const el of json) {
          arr.push(this.getAdjustedValue(el));
        }
        return arr;
      } else {
        return this.toCamelCaseObject(json);
      }
    }

    return json;
  }

  private toCamelCaseObject(json: Record<string, any>) {
    const obj: Record<string, any> = {};
    for (const [field, value] of Object.entries(json)) {
      const cField = camelCase(field);
      obj[cField] = this.getAdjustedValue(value);
    }
    return obj;
  }

  private getVerifiedRecord(json: Record<string, any>): Record<string, any> {
    const validFields = Object.keys(this);
    const obj: Record<string, any> = {};
    for (const [field, value] of Object.entries(json)) {
      const cField = camelCase(field);
      if (!validFields.includes(cField)) {
        throw new Error(`Invalid field: "${cField}", originally "${field}"`);
      }
      obj[cField] = this.getAdjustedValue(value);
    }
    return obj;
  }

  loadData(json: Record<string, any>) {
    this.reset();
    const record = this.getVerifiedRecord(json);
    Object.assign(this, record);
  }

  getResetData() {
    const obj: Record<string, any> = {};
    for (const fieldName of Object.keys(this)) {
      obj[fieldName] = null;
    }
    return obj;
  }

  reset() {
    const blank = this.getResetData();
    Object.assign(this, blank);
  }
}
