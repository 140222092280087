import ListAPI from "@/apps/core/api/list";
import { TargetDiagnosis, TargetDiagnosisAPI } from "./targetDiagnosis";
import ViewListModel from "@/apps/core/models/viewListModel";

export class TargetDiagnosisListAPI extends ListAPI {
  constructor() {
    super(TargetDiagnosisAPI.modelPath);
  }
}

export default class TargetDiagnosisListVM extends ViewListModel {
  constructor(batch = 'all') {
    super(new TargetDiagnosisListAPI());
    this.filterMap.batch = batch;
  }

  getInstance(json: Record<string, any>) {
    const target = new TargetDiagnosis();
    target.loadData(json);
    if (this.filterMap.batch === 'create') target.id = null;
    return target;
  }
}
