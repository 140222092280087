import { RouteConfig } from "vue-router";
import APP_CONFIG from "@/apps/core/modules/config";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";

const baseName = "anggota";
const baseUrl = `/${baseName}`;

const routes: Array<RouteConfig> = [
  // Home
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "anggota" */ "../views/Home.vue"),
  },

  // Anggota router
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "",
        name: `${baseName}-list`,
        component: () =>
          import(/* webpackChunkName: "anggota" */ "../views/AnggotaList.vue"),
      },
      {
        path: "me",
        name: baseName + "-me",
        props: { id: "me" },
        component: () =>
          import(
            /* webpackChunkName: "anggota" */ "../views/AnggotaMe.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["anggota.view_anggota"],
        },
      },
      {
        path: `${APP_CONFIG.idURLPattern}/pencapaian-diagnosis`,
        name: "pencapaian-diagnosis",
        component: () =>
          import(
            /* webpackChunkName: "pencapaiandiagnosis" */ "../views/PencapaianDiagnosisDetail.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["anggota.view_residen", "aktivitas.view_pencapaiandiagnosis"],
        },
      },
      {
        path: `${APP_CONFIG.idURLPattern}/pencapaian-keterampilan`,
        name: "pencapaian-keterampilan",
        component: () =>
          import(
            /* webpackChunkName: "pencapaianketerampilan" */ "../views/PencapaianKeterampilanDetail.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["anggota.view_residen", "aktivitas.view_pencapaianketerampilan"],
        },
      },
      {
        path: APP_CONFIG.idURLPattern,
        name: `${baseName}-detail`,
        component: () =>
          import(
            /* webpackChunkName: "anggota" */ "../views/AnggotaDetail.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["anggota.view_anggota"],
        },
      },
      {
        path: `${baseName}/${APP_CONFIG.idURLPattern}/ubah`,
        name: "anggota-change",
        props: { headerText: "ANGGOTA" },
        component: () =>
          import(/* webpackChunkName: "anggota" */ "../views/AnggotaForm.vue"),
        meta: {
          groups: ["admin"],
          permissions: ["anggota.change_anggota"],
        },
      },
      {
        path: "konsulen/tambah",
        name: "konsulen-add",
        props: { headerText: "KONSULEN" },
        component: () =>
          import(/* webpackChunkName: "konsulen" */ "../views/KonsulenForm.vue"),
        meta: {
          groups: ["admin"],
          permissions: ["anggota.add_anggota", "anggota.add_konsulen"],
        },
      },
      {
        path: `konsulen/${APP_CONFIG.idURLPattern}/ubah`,
        name: "konsulen-change",
        props: { headerText: "KONSULEN" },
        component: () =>
          import(/* webpackChunkName: "konsulen" */ "../views/KonsulenForm.vue"),
        meta: {
          groups: ["admin", "konsulen"],
          permissions: ["anggota.change_anggota", "anggota.change_konsulen"],
        },
      },
      {
        path: "residen",
        name: "residen-list",
        component: () =>
          import(/* webpackChunkName: "residen" */ "../views/ResidenList.vue"),
        meta: {
          groups: ["admin", "konsulen"],
          permissions: ["anggota.view_residen"],
        },
      },
      {
        path: "residen/tambah",
        name: "residen-add",
        props: { headerText: "RESIDEN" },
        component: () =>
          import(/* webpackChunkName: "residen" */ "../views/ResidenForm.vue"),
        meta: {
          groups: ["admin"],
          permissions: ["anggota.add_anggota", "anggota.add_residen"],
        },
      },
      {
        path: `residen/${APP_CONFIG.idURLPattern}/ubah`,
        name: "residen-change",
        props: { headerText: "RESIDEN" },
        component: () =>
          import(/* webpackChunkName: "residen" */ "../views/ResidenForm.vue"),
        meta: {
          groups: ["admin", "residen"],
          permissions: ["anggota.change_anggota", "anggota.change_residen"],
        },
      },
      {
        path: `residen/${APP_CONFIG.idURLPattern}`,
        name: "residen-detail",
        component: () =>
          import(
            /* webpackChunkName: "residen" */ "../views/ResidenDetail.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["anggota.view_residen"],
        },
      },
      // {
      //   path: `${APP_CONFIG.idURLPattern}/ubah`,
      //   name: baseName + "-change",
      //   props: {
      //     headerText: "UBAH",
      //     anggotaMdl: new Anggota()
      //   },
      //   component: () => import("../views/CommonAnggotaCreateUpdate.vue"),
      //   meta: {
      //     groups: ["admin_pusat", "admin_bagian"],
      //     permissions: ["anggota.change_anggota"]
      //   }
      // },
    ],
  },
];

export default routes;
