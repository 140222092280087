import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
// import axios from "axios";

export class KegiatanPendukung extends PlainModel {
  residen: Record<string, string> | null = null;
  lokasi: Record<string, string> | null = null;
  jenis: Record<string, any> | null = null;
  tanggal: string | null = null;
  konsulen: Record<string, string> | null = null;
  catatan: string | null = null;
  verifikasi: boolean | null = null;
  dokumenSet: Array<Record<string, string>> | null = null; //!!!!

//   async updateVerifikasi(
//     url: string,
//     aktDiagSet: Array<Record<string, string>>,
//     aktKetSet: Array<Record<string, string>>
//   ) {
//     await axios.patch(url, {
//       verifikasi: this.verifikasi,
//       'akt_diag_set': aktDiagSet,
//       'akt_ket_set': aktKetSet,
//     });
//   }
}

export class KegiatanPendukungAPI extends API {
  static modelPath = "/aktivitas/kegiatan-pendukung/";

  constructor() {
    super(KegiatanPendukungAPI.modelPath);
  }
}

export default class KegiatanPendukungVM extends ViewModel {
  constructor() {
    super(new KegiatanPendukungAPI(), new KegiatanPendukung());
  }
}
