/*  Informasi store */

import InformasiListVM from "../../models/informasiList";

const listVM = new InformasiListVM();

// Mutations
const setRefreshRequest = (requestBy: string) =>
  listVM.setRefreshRequest(requestBy);

export { listVM, setRefreshRequest };
