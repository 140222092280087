import { computed, reactive } from "@vue/composition-api";
import APP_CONFIG from "./config";

// State
const state: Record<string, any> = reactive({
  isOnline: true,
  nextUrl: "",
  screenWidth: window.innerWidth,
  // activeBNavMenu: "", tidak digunakan, highlight menu menggunakan router-link
});
// export default state; // Jangan di-expose, hanya boleh akses via getter dan mutation

// Getters
export const isOnline = computed(() => state.isOnline);
export const homeNextUrl = computed(() => state.nextUrl);
export const isMobile = computed(() => {
  const result = state.screenWidth <= APP_CONFIG.mobileScreenWidth;
  return result;
});

// Mutations
export const setOnline = (value = true) => (state.isOnline = value);
export const setHomeNextUrl = (value: string) => (state.nextUrl = value);
export const setScreenWidth = () => {
  state.screenWidth = window.innerWidth;
};
