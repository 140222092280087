import { RouteConfig } from "vue-router";
import APP_CONFIG from "@/apps/core/modules/config";
import EmptyRouterView from "@/apps/core/views/EmptyRouterView.vue";

const baseName = "karyailmiah";
const baseUrl = `/${baseName}`;

const routes: Array<RouteConfig> = [
  // KaryaIlmiah router
  {
    path: baseUrl,
    component: EmptyRouterView,
    children: [
      {
        path: "",
        name: `${baseName}-list`,
        component: () =>
          import(
            /* webpackChunkName: "karyailmiah" */ "../views/KaryaIlmiahList.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["karyailmiah.view_karyailmiah"],
        },
      },
      {
        path: APP_CONFIG.idURLPattern,
        name: `${baseName}-detail`,
        props: { title: "Karya Ilmiah" },
        component: () =>
          import(
            /* webpackChunkName: "karyailmiah" */ "../views/KaryaIlmiahDetail.vue"
          ),
        meta: {
          groups: ["admin", "konsulen", "residen"],
          permissions: ["karyailmiah.view_karyailmiah"],
        },
      },
      {
        path: "tambah",
        name: `${baseName}-add`,
        component: () =>
          import(
            /* webpackChunkName: "karyailmiah" */ "../views/KaryaIlmiahForm.vue"
          ),
        meta: {
          groups: ["admin"],
          permissions: ["karyailmiah.add_karyailmiah"],
        },
      },
      {
        path: `${APP_CONFIG.idURLPattern}/ubah`,
        name: `${baseName}-change`,
        component: () =>
          import(
            /* webpackChunkName: "karyailmiah" */ "../views/KaryaIlmiahForm.vue"
          ),
        meta: {
          groups: ["admin"],
          permissions: ["karyailmiah.change_karyailmiah"],
        },
      },
    ],
  },
];

export default routes;
