import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";

class Stase extends PlainModel {
  kompetensi: string | null = null;
  subDivisi: Record<string, string> | null = null;
  angkatan: Record<string, string> | null = null;
  mulai: string | null = null;
  hingga: string | null = null;
  staseresidenSet: Array<any> | null = null;
  extraData: Record<string, string> | null = null;
}

class StaseAPI extends API {
  static modelPath = "/stase/";

  constructor() {
    super(StaseAPI.modelPath);
  }
}

export default class StaseVM extends ViewModel {
  constructor() {
    super(new StaseAPI(), new Stase());
  }
}

export { Stase, StaseAPI };
